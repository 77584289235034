var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cog-input",
      class: { "is-read-only": _vm.readonly, "cog-input--icon": _vm.search }
    },
    [
      _vm.readonly && _vm.type === "url" && _vm.internalValue
        ? [
            _c("a", { attrs: { href: _vm.internalValue, target: "_blank" } }, [
              _vm._v(_vm._s(_vm.internalValue))
            ])
          ]
        : _vm.readonly
        ? [_vm._v(_vm._s(_vm.passwordValue || _vm.internalValue || " "))]
        : _vm.mask
        ? _c(
            "el-input",
            _vm._b(
              {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask.short",
                    value: _vm.mask,
                    expression: "mask",
                    modifiers: { short: true }
                  }
                ],
                ref: "input",
                attrs: {
                  id: _vm.id ? "cog-" + _vm.id : _vm.automaticId,
                  type: _vm.inputType,
                  value: _vm.internalValue,
                  placeholder: _vm.placeholder
                },
                on: {
                  focus: _vm.handleFocus,
                  blur: _vm.handleBlur,
                  change: _vm.handleChange,
                  input: _vm.handleInput
                },
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              },
              "el-input",
              _vm.$attrs,
              false
            ),
            [
              _vm.search
                ? _c("i-search", { attrs: { slot: "suffix" }, slot: "suffix" })
                : _vm._e()
            ],
            1
          )
        : _c(
            "el-input",
            _vm._b(
              {
                ref: "input",
                attrs: {
                  id: _vm.id ? "cog-" + _vm.id : _vm.automaticId,
                  autosize: { minRows: _vm.minRows, maxRows: 8 },
                  type: _vm.inputType,
                  value: _vm.internalValue,
                  placeholder: _vm.placeholder,
                  resize: _vm.$parent.isTable ? "none" : "both"
                },
                on: {
                  focus: _vm.handleFocus,
                  blur: _vm.handleBlur,
                  change: _vm.handleChange,
                  input: _vm.handleInput
                }
              },
              "el-input",
              _vm.$attrs,
              false
            ),
            [
              _vm.search
                ? _c("i-search", { attrs: { slot: "suffix" }, slot: "suffix" })
                : _vm._e()
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }