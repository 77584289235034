<template>
	<div class="cog-input" :class="{'is-read-only': readonly, 'cog-input--icon': search}">
		<template v-if="readonly && type === 'url' && internalValue">
			<a :href="internalValue" target="_blank">{{ internalValue }}</a>
		</template>
		<!-- whitespace on the following line is significant -->
		<template v-else-if="readonly">{{ passwordValue || internalValue || "&nbsp;" }}</template>
		<!-- INFO: v-model is necessary for masked inputs -->
		<el-input
			v-else-if="mask"
			:id="id ? 'cog-' + id : automaticId"
			ref="input"
			v-model="internalValue"
			v-mask.short="mask"
			:type="inputType"
			v-bind="$attrs"
			:value="internalValue"
			:placeholder="placeholder"
			@focus="handleFocus"
			@blur="handleBlur"
			@change="handleChange"
			@input="handleInput"
		>
			<i-search v-if="search" slot="suffix" />
		</el-input>
		<el-input
			v-else
			:id="id ? 'cog-' + id : automaticId"
			ref="input"
			:autosize="{minRows: minRows, maxRows: 8}"
			v-bind="$attrs"
			:type="inputType"
			:value="internalValue"
			:placeholder="placeholder"
			:resize="$parent.isTable ? 'none' : 'both'"
			@focus="handleFocus"
			@blur="handleBlur"
			@change="handleChange"
			@input="handleInput"
		>
			<i-search v-if="search" slot="suffix" />
		</el-input>
	</div>
</template>

<script>
	import 'src/style/_input-icon.scss';

	import Vue from 'vue';
	import { Input } from '@cognitoforms/element-ui';
	import { facade } from 'vue-input-facade';
	import focus from '../mixins/focus.ts';
	import ISearch from 'src/assets/search.svg';

	Vue.directive('mask', facade);
	Vue.use(Input);

	let AUTOMATIC_ID = 0;

	export default {
		name: 'CInput',
		components: { ISearch },
		mixins: [focus],
		inject: ['flags'],
		inheritAttrs: false,
		model: {
			prop: 'value',
			event: 'change'
		},
		props: {
			value: { type: String, default: '' },
			type: { type: String, default: 'text' },
			mask: { type: String, default: null },
			id: { type: String, default: null },
			placeholder: { type: String, default: '' },
			readonly: Boolean,
			maxlength: { type: Number, default: undefined },
			search: { type: Boolean, default: false }
		},
		data: function () {
			return {
				internalValue: this.value,
				minRows: this.$parent.isTable ? null : 2,
				automaticId: this.id || `cog-input-auto-${AUTOMATIC_ID++}`,
				hasCalledChange: false
			};
		},
		computed: {
			passwordValue() {
				// mask password fields when they are readonly
				return (this.readonly && this.type === 'password') ? this.internalValue.replace(/./g, '•') : this.internalValue;
			},
			inputType() {
				// Show passwords as plaintext when editing on the entries page
				if (this.type === 'password' && !this.readonly && this.flags.plaintextPasswords)
					return 'text';
				else
					return this.type;
			}
		},
		watch: {
			// NOTE: This is here to watch for changes to the prop coming from OUTSIDE of the component...
			value: function (valueProp) {
				this.internalValue = valueProp;
			}
		},
		created () {
			if (this.type === 'textarea' && process.env.IS_LEGACY)
				this.$root.setPolyFillCallBack(() => {this.$refs.input.resizeTextarea();});
		},
		methods: {
			handleFocus(ev) {
				if (!this.readonly && this.mask) {
					// Capture the value that the input had when focussed
					const focusValue = this.internalValue;
					// Delay a short time using `setTimeout`, which should be short
					// enough that a user can't have provided input, but long enough
					// to occur after Vue has upadted the directive and fired events
					setTimeout(() => {
						// If the input value is changed by the mask, then fire the change
						// event so that the model updates, otherwise change will never fire
						if (this.isFocused && this.internalValue !== focusValue)
							this.$emit('change', this.internalValue.trim());
					}, 0);
				}
				this.addClassOnFocus();
				this.$emit('focus', ev);
			},
			async handleBlur(ev) {
				// Manually brodcast change event.
				// Some browsers (FireFox, Legacy Edge) may have eventing issues that will not broadcast the change event, so handleChange() is not called.
				if ((!this.hasCalledChange || this.mask || this.maxlength) && (this.internalValue !== this.value)) {
					this.handleChange(this.internalValue);
					await this.$nextTick();
				}

				this.removeClassOnBlur();
				this.$emit('blur', ev);
				await this.$nextTick();

				if (this.internalValue !== this.value)
					this.internalValue = this.value;
				this.hasCalledChange = false;
			},
			handleInput(newVal) {
				// Constrain input value to maxlength
				if (!isNaN(this.maxlength) && this.maxlength < newVal.length)
					newVal = newVal.substr(0, this.maxlength);

				// Coerce blank string to null since we rely on this behavior in calculation logic (ex: conditional visibility)
				if (newVal === '')
					newVal = null;

				this.internalValue = newVal;
				this.$emit('input', newVal);
			},
			handleChange(newVal) {
				this.hasCalledChange = true;
				newVal = newVal && newVal.trim();
				// Coerce blank string to null since we rely on this behavior in calculation logic (ex: conditional visibility)
				if (newVal === '')
					newVal = null;

				this.$emit('change', newVal);
			}
		}
	};
</script>

<style lang="scss">
#{$specificity-base} {

	.cog-input {

		.el-textarea {
			display: flex;
		}

		.el-textarea__inner {
			// without this IE always has a scrollbar: 17347
			overflow: auto;
			resize: vertical;
		}
	}
}
</style>
